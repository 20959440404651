<template>
  <Layout>
    <section class="csms-ad">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3" v-if="$_app.user.roles[0] === 'ADMIN'">
                      <label class="form-label" for="ad-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="ad-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="ad-search-title">{{ $t('ad.title') }}</label>
                      <b-form-input class="form-control" type="text" id="ad-search-title"
                                    v-model="searchForm.title" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="ad-search-period">{{ $t('ad.period') }}</label>
                      <div>
                        <date-picker type="date" range
                                     :placeholder="$t('alert.selectDateRange')"
                                     v-model="searchForm.period" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('ad.noAd')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`ad.${data.column}`) }}</span>
                  </template>

                  <template #cell(checkbox)="data">
                    <input type="checkbox" class="form-check-input" v-model="selectedAd" :value="data.item.id">
                  </template>

                  <template #cell(hqName)="data">
                    {{ !$_utils.isEmpty(data.item.hqBusiness) ? data.item.hqBusiness.hqName : '-' }}
                  </template>
                  <template #cell(title)="data">
                    <a href="#" @click="view(data.item.id)">{{ data.item.title }}</a>
                  </template>
                  <template #cell(period)="data">
                    <template v-if="data.item.displayType !== 'Disable'">
                      {{ $_formatter.timestampToDate(data.item.startDate) }} ~ {{ $_formatter.timestampToDate(data.item.endDate) }}
                    </template>
                    <template v-else>-</template>
                  </template>
                  <template #cell(displayType)="data">
                    {{ $_formatter.adDisplayTypeName(data.item.displayType) }}
                  </template>
                  <template #cell(etc)="data">
                    <button class="btn btn-sm btn-warning w-sm" @click="edit(data.item.id)">{{ $t('btn.edit') }}</button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <!-- Bottom Button Start -->
              <div class="row">
                <div class="col-xl-12">
                  <div class="float-start">
                    <b-button variant="danger" @click="del">
                      <i class="uil uil-minus me-2"></i>
                      <span>{{ $t('btn.delete') }}</span>
                    </b-button>
                  </div>
                  <div class="float-end">
                    <b-button variant="success" @click="create">
                      <i class="uil uil-plus me-2"></i>
                      <span>{{ $t('btn.create') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- Bottom Button End -->

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <create-modal ref="adCreateModal" @callback="getItems" />
      <view-modal ref="adViewModal" :selected-id="selectedId" @callback="getItems" />
      <edit-modal ref="adEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import CreateModal from '@/views/pages/advertisement/AdCreateModal.vue';
import ViewModal from '@/views/pages/advertisement/AdViewModal.vue';
import EditModal from '@/views/pages/advertisement/AdEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, CreateModal, ViewModal, EditModal
  },
  data() {
    return {
      title: 'manageAd',
      selectedId: null,
      selectedAd: [],
      hqOptions: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        hqId: { text: i18n.t('common.total'), value: null },
        title: null,
        period: null
      },
      fields: [
        { key: 'checkbox', label: '', class: 'text-center' },
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'title', label: '제목', class: 'text-center' },
        { key: 'link', label: '링크', class: 'text-center' },
        { key: 'period', label: '표시 기간', class: 'text-center' },
        { key: 'displayType', label: '표시 상태', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    reset() {
      const vm = this;

      vm.selectedId = null;
      vm.searchForm = {
        hqId: { text: i18n.t('common.total'), value: null },
        title: null,
        period: null
      };
      vm.searchConditions = [];

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.title)) {
        vm.searchConditions.push({ searchKey: 'title', searchValue: vm.searchForm.title });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD 00:00:00') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD 23:59:59') });
      }

      vm.pagination.currentPage = 1;
      vm.getItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.hqId.value)
          && this.$_utils.isEmpty(vm.searchForm.title)
          && this.$_utils.isEmpty(vm.searchForm.period)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.title)) {
          vm.searchConditions.push({ searchKey: 'title', searchValue: vm.searchForm.title });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.period)) {
          vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD 00:00:00') });
          vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD 23:59:59') });
        }

        vm.pagination.currentPage = 1;
        vm.getItems();
      }*/
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/AdMgmt/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Advertisement List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getItems();
      }
    },
    create() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.adCreateModal.show();
      });
    },
    view(id) {
      const vm = this;
      vm.selectedId = id;

      this.$nextTick(() => {
        this.$refs.adViewModal.show();
      });
    },
    edit(id) {
      const vm = this;
      vm.selectedId = id;

      this.$nextTick(() => {
        this.$refs.adEditModal.show();
      });
    },
    del() {
      const vm = this;

      if (vm.selectedAd.length < 1) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.selectAd'),
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmDelete'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              for (const item of vm.selectedAd) {
                let _url = `/onmapi/view/AdMgmt/delete/${item}`;

                try {
                  await http.delete(_url);
                  console.info('AdMgmt Delete Success !!', item);
                } catch (err) {
                  console.error('AdMgmt Delete Error !!', err);
                }
              }

              vm.selectedAd = [];
              await vm.getItems();

              await Swal.fire({
                icon: 'info',
                title: i18n.t('alert.deleted'),
                confirmButtonText: i18n.t('btn.close')
              });
            })();
          }
        })
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
