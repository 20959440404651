<template>
  <b-modal header-class="ion-bg-modal-header" size="lg"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('ad.adTitle') }} {{ $t('common.create') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="ad-create-title">
              <span>{{ $t('ad.title') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="ad-create-title"
                          v-model="form.title"
                          :class="{ 'is-invalid': submitted && $v.form.title.$error }" />
            <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
              <span v-if="!$v.form.title.required">{{ $t('validation.titleIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="ad-create-file">
              <span>{{ $t('common.file') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="input-group">
              <input class="form-control"
                     type="file"
                     id="ad-create-file"
                     @change="fileCheck"
                     :class="{ 'is-invalid': submitted && $v.adImage.$error }" />
              <div v-if="submitted && $v.adImage.$error" class="invalid-feedback">
                <span v-if="!$v.adImage.required">{{ $t('validation.fileIsRequired') }}</span>
              </div>
            </div>
            <div class="text-black-50 ps-2 pt-2">- 480 x 320 {{ $t('ad.uploadRatioImage') }}</div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="ad-create-link">{{ $t('ad.link') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="ad-create-link"
                          v-model="form.link" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="ad-create-display-type">
              <span>{{ $t('ad.displayType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="displayTypeBtn1" autocomplete="off"
                     v-model="form.displayType" value="Period" @click="changePeriod('Period')" />
              <label class="btn btn-outline-secondary" for="displayTypeBtn1">{{ $t('ad.statusPeriod') }}</label>
              <input type="radio" class="btn-check" id="displayTypeBtn2" autocomplete="off"
                     v-model="form.displayType" value="Enable" @click="changePeriod('Enable')" />
              <label class="btn btn-outline-secondary" for="displayTypeBtn2">{{ $t('ad.statusEnable') }}</label>
              <input type="radio" class="btn-check" id="displayTypeBtn3" autocomplete="off"
                     v-model="form.displayType" value="Disable" @click="changePeriod('Disable')" />
              <label class="btn btn-outline-secondary" for="displayTypeBtn3">{{ $t('ad.statusDisable') }}</label>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="form.displayType !== 'Disable'">
          <div class="col-xl-6">
            <label class="form-label" for="ad-create-period">
              <span>{{ $t('ad.period') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="row">
              <date-picker type="date" range
                           :placeholder="$t('alert.selectDateRange')"
                           v-model="form.period"
                           :class="{ 'is-invalid': submitted && $v.form.period.$error }" />
              <div v-if="submitted && $v.form.period.$error" class="invalid-feedback">
                <span v-if="!$v.form.period.required">{{ $t('validation.periodIsRequired') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="$_app.user.roles[0] === 'ADMIN'">
          <div class="col-xl-6">
            <label class="form-label" for="ad-create-hq">{{ $t('common.headquarters') }}</label>
            <multiselect id="ad-create-hq"
                         v-model="hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         openDirection="top"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true" />
          </div>
        </div>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      visible: false,
      submitted: false,
      hqOptions: [],
      hqId: null,
      form: {
        title: null,
        link: '',
        displayType: 'Period',
        period: null
      },
      adImage: null
    }
  },
  validations() {
    const isDisable = this.form.displayType === 'Disable';

    return {
      form: {
        title: { required },
        period: { required: isDisable ? false : required }
      },
      adImage: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;

      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).then(() => {
        if (this.$_app.user.roles[0] === 'HQ') {
          vm.hqId = vm.hqOptions.find(ele => ele.value === this.$_app.user.hqId);
        }
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.hqOptions = [];
      vm.hqId = null;

      vm.form = {
        title: null,
        link: '',
        displayType: 'Period',
        period: null
      };
      vm.adImage = null;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changePeriod(_type) {
      const vm = this;

      if (_type === 'Enable') {
        vm.form.period = [new Date(), new Date(9999, 11, 31)]
      } else {
        vm.form.period = null;
      }
    },
    fileCheck(e) {
      const vm = this;
      vm.adImage = e.target.files[0];
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmCreate'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/AdMgmt/create';

      const _formData = new FormData();

      _formData.append('title', vm.form.title);
      _formData.append('link', !this.$_utils.isEmpty(vm.form.link) ? vm.form.link : '');
      _formData.append('file', vm.adImage);

      if (vm.form.displayType !== 'Disable') {
        _formData.append('startDate', this.$moment(vm.form.period[0]).format('YYYY-MM-DD 00:00:00'));
        _formData.append('endDate', this.$moment(vm.form.period[1]).format('YYYY-MM-DD 00:00:00'));
      } else {
        _formData.append('startDate', '1970-01-01 00:00:00');
        _formData.append('endDate', '1970-01-01 00:00:00');
      }

      if (!this.$_utils.isEmpty(vm.hqId)) {
        _formData.append('hqId', vm.hqId.value);
      }

      http.post(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.created'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('AD Create Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
