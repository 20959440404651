<template>
  <b-modal header-class="ion-bg-modal-header" size="lg"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('ad.adTitle') }}</div>
    </template>

    <div class="row" v-if="!$_utils.isEmpty(item)">
      <div class="col-xl-12">
        <div class="card shadow-none mb-0">
          <div class="card-body">

            <!-- Title Start -->
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-sm">
                  <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                    <i class="mdi mdi-television"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1 overflow-hidden mb-1">
                <h5 class="font-size-18 fw-bold">
                  <span class="badge rounded-pill me-2" :class="showPeriodBadge(item.displayType)">
                    {{ showPeriodTitle(item.displayType) }}
                  </span>
                  <span>{{ item.title }}</span>
                </h5>
                <div v-if="item.displayType !== 'Disable'">
                  <span class="font-size-14 text-muted">{{ $t('ad.period') }} :</span>
                  <span class="ps-2">{{ item.startDate }} ~ {{ item.endDate }}</span>
                </div>
                <div>
                  <span class="font-size-14 text-muted me-2">{{ $t('ad.link') }} :</span>
                  <a :href="getLink()" target="_blank" class="small fw-semibold text-decoration-underline">{{ item.link }}</a>
                </div>
                <div v-if="item.previewFile">
                  <span class="font-size-14 text-muted me-2">{{ $t('common.attachment') }} :</span>
                  <a :href="`/onmapi/view/File/download/${item.previewFile.fileId}`" download>
                    {{ item.previewFile.orgName }}
                  </a>
                </div>
                <div>
                  <span class="font-size-14 text-muted">{{ $t('ad.created') }} : {{ item.created }}</span>
                </div>
              </div>
            </div>
            <!-- Title End -->

            <hr />

            <!-- Contents Start -->
            <div class="row text-center">
              <b-img :src="item.previewFile.filePath"
                     rounded width="300" fluid alt="advertise image"
                     v-if="item.previewFile"
                     class="me-2" />
            </div>
            <!-- Contents End -->

          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1" @click="close">{{ $t('btn.close') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';

export default {
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      item: null
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getItem();
      vm.visible = true;
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/AdMgmt/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
      }).catch((err) => {
        console.error('Advertisement Retrieve Error !!', err)
      });
    },
    close() {
      const vm = this;

      vm.item = null;
      this.$emit('callback');
      vm.visible = false;
    },
    showPeriodTitle(str) {
      switch (str) {
        case 'Period': return i18n.t('ad.statusPeriod');
        case 'Enable': return i18n.t('ad.statusEnable');
        case 'Disable': return i18n.t('ad.statusDisable');
        default: return '';
      }
    },
    showPeriodBadge(str) {
      switch (str) {
        case 'Period': return 'badge-soft-warning';
        case 'Enable': return 'badge-soft-primary';
        case 'Disable': return 'badge-soft-secondary';
        default: return '';
      }
    },
    getLink() {
      const vm = this;
      return vm.item.link.includes('http') ? vm.item.link : `http://${vm.item.link}`
    }
  }
}
</script>
